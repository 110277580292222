import React, { Component } from 'react';
import {Container, Row, Col, Navbar, Nav, Button,Image} from 'react-bootstrap';
import { Parallax, Background } from 'react-parallax';
import logo from './images/logo.svg'
import './App.scss';
import ReactPlayer from 'react-player';
import {TweenLite,ScrollToPlugin} from 'gsap/all';

const HEADER_BIG=100;

const OFFSET=15;
const scrollTo=ScrollToPlugin;

class App extends Component {
  constructor(props){
    super(props)
    this.handleNav=this.handleNav.bind(this)
    this.clickItemMenus=this.clickItemMenus.bind(this)
    this.appRef=React.createRef()
    this.elearning=React.createRef()
    this.video=React.createRef()
    this.rapid=React.createRef()
    this.tween=null;
  }
  componentWillMount(){
      window.addEventListener('scroll', this.handleScroll, { passive: true })
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  handleScroll(){

  }
  clickItemMenus(event){
    if(event.currentTarget.dataset.id!==undefined){
        this.handleNav(event.currentTarget.dataset.id)

    }
}
  handleNav(params){
    console.log("params",params)
   // console.log(this.elearning.current)
    const node=this[params].current;
    console.log(this[params].current)
    console.log("node ", node.getBoundingClientRect().top);
    const toReach=this.getPosition(node)+OFFSET
    console.log("value",toReach,this.appRef.current)
    //
    //console.log(scrollTo);
    TweenLite.to(window,0.5,{scrollTo:{y:toReach}});
  }
  getPosition(node){
    const rect=node.getBoundingClientRect();
    return rect.top+window.scrollY-HEADER_BIG
  }
  render(){
    return(
        <Container className="app" align="center">
          <Container className="header" align="center">
            <Row><p>{"©Obea - Espace de démonstration - ne pas diffuser"}</p></Row>
           <Navbar className="menu" collapseOnSelect expand="lg" fixed="top">
               <Navbar.Brand href="#home">
                 <img
                   src={logo}
                   width="120px"
                   className="d-inline-block align-top"
                   alt="Obea logo"
                 />       
               </Navbar.Brand>
               <Navbar.Toggle aria-controls="responsive-navbar-nav" />
               <Navbar.Collapse id="responsive-navbar-nav">  
                 <Nav className="mr-auto">
                      <Nav.Link data-id={"etagere"}className="item" onClick={this.clickItemMenus}><p>Modules <br />sur étagère</p></Nav.Link>
                     <Nav.Link data-id={"elearning"}className="item" onClick={this.clickItemMenus}><p>{" Learning game \n& Learning App"}</p></Nav.Link>
                     <Nav.Link data-id={"rapid"} className="item" onClick={this.clickItemMenus}><p>E-learning <br/> sur-mesure</p></Nav.Link>
                     <Nav.Link data-id={"video"} className="item" onClick={this.clickItemMenus}><p>Vidéos <br />et motion-design</p></Nav.Link>
                 </Nav>
               </Navbar.Collapse>  
             </Navbar>
         </Container>
         <Container align="center" className="callAction">
           <Parallax
                   blur={1}
                   bgImage={require('./images/obeal-dl-violet.png')}
                   bgImageAlt="the cat"
                   strength={500}
               >
               <h3>
                 Découvrez une sélection de réalisations 100% Obea !
               </h3>
               <div className="blank"></div>
               </Parallax>
         </Container>
         <Container className="section" ref={this.etagere}>
            <h2 id="rapid" >Nos modules sur étagère</h2>
            <Row>
              <Col className="picto-rapid" md="3">
                <Image src="./data/assets/thumb-ie.png" thumbnail fluid/>
              </Col>
              <Col md="9">
                <h4>Les clés de l'intelligence émotionnelle</h4>
                <p>Un module complet, mélant éclairages didactiques, mises en situations concrètes, introspection sur ces pratiques pour faire de l'intelligence émotionnelle un levier d'action de son quotidien professionnel.</p>
                <Button className="button" href="https://obea.de-mots.com/ie/#/" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
              </Col>
            </Row>
            <Row>
              <Col className="picto-rapid" md="3">
                <Image src="./data/assets/thumb-mad.png" thumbnail fluid/>
              </Col>
              <Col md="9">
                <h4>Les clés du management à distance </h4>
                <p>Un module court et inspirant pour interroger le rôle et la posture managériales dans un contexte de «déspatialisation» et d'animation des collectifs de travail à distance.</p>
                <Button className="button" href="https://obea.de-mots.com/mad/#/" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
              </Col>
            </Row>
            <Row>
              <Col className="picto-rapid" md="3">
                <Image src="./data/assets/thumb-tad.png" thumbnail fluid/>
              </Col>
              <Col md="9">
                <h4>Les clés du travail à distance</h4>
                <p>Un module spécifique, à destination des collaborateurs et des agents pour mieux appréhender les spécificités du travail à distance et éviter les éccueils de cette pratique professionnelle.</p>
                <Button className="button" href="https://obea.de-mots.com/tad/#/" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
              </Col>
            </Row>
            <Row>
              <Col className="picto-rapid" md="3">
                <Image src="./data/assets/thumb-key.png" thumbnail fluid/>
              </Col>
              <Col md="9">
                <h4>Nos keynote</h4>
                <p>Une collection complète d'entretiens avec des experts reconnus sur les sujets de pointe du management d'aujourd'hui.</p>
                <Button className="button" href="https://obea.de-mots.com/keynotes/legitimite/#/" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
              </Col>
            </Row>
            <Row>
              <Col className="picto-rapid" md="3">
                <Image src="./data/assets/thumb-exp.png" thumbnail fluid/>
              </Col>
              <Col md="9">
                <h4>Nos explainers</h4>
                <p>Des modules courts et inspirants pour décoder et appliquer les principaux concepts ou méthodes qui articulent les approches managériales les plus efficaces.</p>
                <Button className="button" href="https://obea.de-mots.com/explainer/feedback/" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
              </Col>
            </Row>
        </Container>
         <Container className="section" ref={this.elearning}>
           <h2 id="rapid" >Nos learning APP et learning game</h2>
           <Row>
              <Col className="picto-rapid" md="3">
                <Image src="./data/assets/thumb-sp.png" thumbnail fluid/>
              </Col>
              <Col md="9">
                <h4>Services Publics +</h4>
                <p>Une collection de « learning app » associant apports méthodologiques de haut-niveau, issus des travaux des sciences comportementales, saynètes interactives en vidéos, et ressources pratiques pour aller plus loin.</p>
                <Button className="button" href="https://obea.de-mots.com/eds/m1/#/" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
              </Col>
            </Row>
            <Row>
              <Col className="picto-rapid" md="3">
                <Image src="./data/assets/thumb-choices.png" thumbnail fluid/>
              </Col>
              <Col md="9">
                <h4>CHOICES</h4>
                <p>Un learning game aux illustrations extrêmement soignées pour s'interroger, analyser et mesurer les enjeux RSE sur toutes les activités du Groupe</p>
                <Button className="button" href="http://obea.de-mots.com/SG/choices" variant="outline-info" target="_blank" size="lg">Lancer le projet </Button>
              </Col>
            </Row>
           <Row>
             <Col className="picto-rapid" md="3">
               <Image src="./data/assets/thumb-cortex.png" thumbnail fluid/>
             </Col>
             <Col md="9">
               <h4>Société Générale - CORTEX</h4>
               <p>CORTEX est un dipositif interactif et immersif qui plonge les apprenants au coeur de la gestion de crise.</p>
               <Button className="button" href="http://obea.de-mots.com/SG/cortex" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
             </Col>
           </Row>
           <Row>
             <Col className="picto-rapid" md="3">
               <Image src="./data/assets/thumb-passenger.png" thumbnail fluid/>
             </Col>
             <Col md="9">
               <h4>L'Oréal - The Passenger</h4>
               <p>The passenger est une aventure pédagogique interactive qui reprend les codes des jeux conversationnels sur mobile.</p>
               <Button className="button" href="http://de-mots.com/passenger-fr/#/"variant="outline-info" target="_blank" size="lg">Lancer le projet </Button>
             </Col>
           </Row>
           <Row>
             <Col className="picto-rapid" md="3">
               <Image src="./data/assets/thumb-sga.png" thumbnail fluid/>
             </Col>
             <Col md="9">
               <h4>SGA-GMA ANALYSIS</h4>
               <p>Un module, conçu comme une webApp qui explique de façon concrète et intelligible les mécanismes de calcul, de valorisation et d’analyse de la création de valeur !</p>
               <Button className="button" href="https://app.didask.com/catalogue/courses/prevenir-la-corruption-9J1D" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
             </Col>
           </Row>
         </Container>
         <Container className="section" ref={this.rapid}>
           <h2 id="rapid" >Nos modules e-learning</h2>
           <Row>
              <Col className="picto-rapid" md="3">
                <Image src="./data/assets/thumb-dac6.png" thumbnail fluid/>
              </Col>
              <Col md="9">
                <h4>À LA DÉCOUVERTE DE DAC-6</h4>
                <p>Un module e-learning sur les impacts de la réglementation DAC-6 proposant des parcours différenciés à chaque acteur amené à mettre en oeuvre les exigences de cette nouvelle réglementation.</p>
                <Button className="button" href="http://obea.de-mots.com/SG/dac-6" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
              </Col>
            </Row>
           <Row>
             <Col className="picto-rapid" md="3">
               <Image src="./data/assets/thumb-chemistry.png" thumbnail fluid/>
             </Col>
             <Col md="9">
               <h4>Immerys - Chemistry Academy</h4>
               <p>Une collection de modules basés sur une expérience apprenante à la fois cohérente, simple et pertinente.​</p>
               <Button className="button" variant="outline-info" size="lg" href="./data/projets/scormcontent/index.html" target="_blank">Lancer le projet </Button>
             </Col>
           </Row>
           <Row>
             <Col className="picto-rapid" md="3">
               <Image src="./data/assets/thumb-da.png" thumbnail fluid/>
             </Col>
             <Col md="9">
               <h4>Did Ask - Prévenir la corruption</h4>
               <p>Un ensemble de « graines » pour appréhender les situations à risques.<br /> Utiliser le compte <strong>clement.papin@obea.fr</strong> pour vous connecter</p>
               <Button className="button" href="https://app.didask.com/catalogue/courses/prevenir-la-corruption-9J1D" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
             </Col>
           </Row>
           <Row>
             <Col className="picto-rapid" md="3">
                <Image src="./data/assets/thumb-lefs.png" thumbnail fluid/>
             </Col>
             <Col md="9">
               <h4>IN THE CAN -Tutoriels manageriaux</h4>
               <p>Une collection de guides de déploiement interactifs, basés sur le modèle des  MOOCS » </p>
               <Button className="button" href="./data/projets/Cooperation/index.html" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
             </Col>
           </Row>
           <Row>
             <Col className="picto-rapid" md="3">
             <Image src="./data/assets/thumb-se.png" thumbnail fluid/>
             </Col>
             <Col md="9">
               <h4>Finance Academy Toolkit - la fabrique à contenus interactifs</h4>
               <p>Un ensemble de formats pédagogiques, de règles éditoriales et d’UX, de ressources iconographiques et visuelles pour produire des contenus efficaces.</p>
               <Button className="button" href="./data/projets/PM_M2/index.html" target="_blank" variant="outline-info" size="lg">Lancer le projet </Button>
             </Col>
           </Row>
         </Container>
         <Container className="section" ref={this.video}>
           <h2 id="video">Quelques références en vidéo et motion design</h2>
           <Row>
             <Col md="6" className="video">
               <ReactPlayer 
                 url="./data/videos/MANAGEMENT_DE_CRISE.mp4"
                 controls="true"
               />
               <h4>EXPLAINER - Le management en situation de crise</h4>
             </Col>
           </Row>
           <Row>
             <Col md="6" className="video">
               <ReactPlayer 
                 url="./data/videos/CCO-MODEL.mp4"
                 controls="true"
               />
               <h4>EXPLAINER - Le modèle de performance Schneider Electric</h4>
             </Col>
           </Row>
           <Row>
             <Col md="6" className="video">
               <ReactPlayer 
                 url="./data/videos/EXPLAINER-EPITECH.mp4"
                 controls="true"
               />
               <h4>EXPLAINER - Présentation Epitech</h4>
             </Col>
           </Row>
           <Row>
             <Col md="6" className="video">
               <ReactPlayer 
                 url="./data/videos/EXPLAINER-CNP.mp4"
                 controls="true"
               />
               <h4>EXPLAINER - APP pour CNP</h4>
             </Col>
           </Row>
           <Row>
             <Col md="6" className="video">
               <ReactPlayer 
                 url="./data/videos/DATAVIZ_SNCF.mp4"
                 controls="true"
               />
               <h4>DATAVIZ - conduite du changement pour la SNCF</h4>
             </Col>
           </Row>
           <Row>
             <Col md="6" className="video">
               <ReactPlayer 
                 url="./data/videos/EXPLAINER-AAA.mp4"
                 controls="true"
               />
               <h4>EXPLAINER - APPRENDRE À APPRENDRE</h4>
             </Col>
           </Row>
           <Row>
             <Col md="6" className="video">
               <ReactPlayer 
                 url="./data/videos/HS-sample.mp4"
                 controls="true"
               />
               <h4>CAPTATION - HAIR SECRETS LAB (EXTRAITS)</h4>
             </Col>
           </Row>
           <Row>
             <Col md="6" className="video">
               <ReactPlayer 
                 url="./data/videos/DreamOfLearning_master_1.mp4"
                 controls="true"
               />
               <h4>NARRATIVE - DREAMS OF LEARNING</h4>
             </Col>
           </Row>
         </Container>
       </Container>
       );
  }
}

export default App;
